import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Customers from '../pages/Customers';
import CustomerDetails from '../pages/CustomerDetails';
import NotFound from '../pages/NotFound';
import { CustomerProvider } from '../context/CustomerContext';

const CustomerRoutes: React.FC = () => (
  <CustomerProvider>
    <Routes>
      <Route path="/:id" element={<CustomerDetails />} />
      <Route path="/" element={<Customers />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </CustomerProvider>
);

export default CustomerRoutes;
