import React from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridRenderCellParams,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import { PaymentShortInfo, PaymentStatus } from '../../types/paymentTypes';
import { PaginatedResponse } from '../../types/common';
import { formatDateTime } from '../../utils/dateFormatter';
import Table from '../common/Table';
import StatusChip from './StatusChip';
import slugToWords from '../../utils/slugToWords';
import { formatMoney } from '../../utils/formatMoney';
import FormatUUID from '../common/FormatUUID';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface PaymentTableProps {
  rows: PaginatedResponse<PaymentShortInfo>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  sortModel: GridSortModel;
  setSortModel: (model: GridSortModel) => void;
  loading: boolean;
  columnVisibilityModel: GridColumnVisibilityModel;
  setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void;
}

interface DirectionIconProps {
  direction: string;
}

const DirectionIcon: React.FC<DirectionIconProps> = ({ direction }) => {
  const icon =
    direction === 'PAYIN' ? (
      <span
        style={{
          color: '#77dac9',
        }}
      >
        <ArrowDownward color="inherit" />
      </span>
    ) : (
      <span
        style={{
          color: '#7d42ff',
        }}
      >
        <ArrowUpward color="inherit" />
      </span>
    );

  return (
    <Tooltip title={direction}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

const columns: GridColDef[] = [
  {
    field: 'direction',
    headerName: 'Direction',
    flex: 0,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return <DirectionIcon direction={params.row.direction} />;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      params.row?.amount
        ? formatMoney(params.row.amount, params.row.currencyCode)
        : null,
  },
  {
    field: 'feeAmount',
    headerName: 'Fee amount',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      params.row?.feeAmount
        ? formatMoney(params.row.feeAmount, params.row.currencyCode)
        : null,
  },
  {
    field: 'method',
    headerName: 'Payment method',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      slugToWords(params.row.method),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <StatusChip status={params.value as PaymentStatus} />
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) =>
      formatDateTime(params.row.createdAt),
  },
  {
    field: 'paymentId',
    headerName: 'Payment Id',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <FormatUUID uuid={params.row.paymentId as PaymentStatus} />
    ),
  },
  { field: 'fingateId', headerName: 'Fingate Id', flex: 1, sortable: false },
  {
    field: 'walletId',
    headerName: 'Wallet Id',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <FormatUUID uuid={params.row.walletId as PaymentStatus} />
    ),
  },
];

const PaymentTable: React.FC<PaymentTableProps> = ({
  loading,
  paginationModel,
  rows,
  setPaginationModel,
  setSortModel,
  sortModel,
  columnVisibilityModel,
  setColumnVisibilityModel,
}) => (
  <Table
    rows={rows.items}
    columns={columns}
    paginationModel={paginationModel}
    setPaginationModel={setPaginationModel}
    loading={loading}
    rowCount={rows?.pagination?.total || 0}
    getRowId={item => item.fingateId}
    sortModel={sortModel}
    setSortModel={setSortModel}
    columnVisibilityModel={columnVisibilityModel}
    setColumnVisibilityModel={setColumnVisibilityModel}
  />
);

export default PaymentTable;
