import React from 'react';
import PageTitle from './PageTitle';
import ErrorMessage from './ErrorMessage';

interface PageWrapperProps {
  error: string | null;
  loading: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode | string;
  onResetPageSettings?: () => void;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  error,
  title,
  children,
  onResetPageSettings,
}) => {
  return (
    <>
      <PageTitle title={title} onResetPageSettings={onResetPageSettings} />
      {error ? <ErrorMessage message={error} /> : children}
    </>
  );
};

export default PageWrapper;
