import React from 'react';
import { TextField, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface DateFiltersProps {
  dateStart: string;
  dateEnd: string;
  onDateChange: (field: 'dateStart' | 'dateEnd', value: string) => void;
  errors?: {
    dateStart?: string;
    dateEnd?: string;
  };
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const StyledGrid = React.memo(
  styled(Grid)({
    flexWrap: 'nowrap',
    overflowX: 'auto',
    alignItems: 'baseline',
  })
);

const StyledTextField = styled(TextField)({
  minWidth: 200,
  maxWidth: 300,
});

const DateFilters: React.FC<DateFiltersProps> = ({
  dateStart,
  dateEnd,
  onDateChange,
  errors = {},
}) => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onDateChange(name as 'dateStart' | 'dateEnd', value);
  };

  return (
    <Wrapper>
      <Typography variant="caption" fontWeight="bold">
        Period
      </Typography>
      <StyledGrid container spacing={2} alignItems="center">
        <Grid item>
          <StyledTextField
            label="Date Start"
            name="dateStart"
            type="date"
            value={dateStart}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            error={!!errors.dateStart || !!errors.dateEnd}
            helperText={errors.dateStart || ''}
          />
        </Grid>
        <Grid item>
          <StyledTextField
            label="Date End"
            name="dateEnd"
            type="date"
            value={dateEnd}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            error={!!errors.dateStart || !!errors.dateEnd}
            helperText={errors.dateEnd || ''}
          />
        </Grid>
      </StyledGrid>
    </Wrapper>
  );
};

export default DateFilters;
