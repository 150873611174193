import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import menuItems from '../../data/menuItems';
import { Typography } from '@mui/material';
import PageSettingsMenu from './PageSettings';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface PageTitleProps {
  title?: React.ReactNode | string;
  onResetPageSettings?: () => void;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  onResetPageSettings,
}) => {
  const location = useLocation();

  const currentPage = useMemo(() => {
    if (title) return { text: title };
    return menuItems.find(item => item.path === location.pathname);
  }, [location.pathname, title]);

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        {currentPage?.text || '404 - Page not found'}
      </Typography>
      {onResetPageSettings && (
        <PageSettingsMenu onResetPageSettings={onResetPageSettings} />
      )}
    </Container>
  );
};

export default PageTitle;
