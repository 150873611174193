import { TableFilter } from './common';

export enum UserVerificationStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  CDD_REFRESH = 'CDD_REFRESH',
}

export enum Action {
  VIEW = 'VIEW',
}

export interface Customer {
  actorId: string;
  email: string;
  name: string;
  verificationStatus: UserVerificationStatus;
  createTime: string;
  updateTime: string;
}

export interface SharedCountryInfo {
  id: number;
  isoCode: string;
  name: string;
}

export interface SharedAddressInfo {
  id?: number;
  countryId?: number;
  country?: SharedCountryInfo;
  countryCode?: string;
  city?: string;
  postcode?: string;
  address1?: string;
  address2?: string;
}

export interface CustomerDetails extends Omit<Customer, 'name' | 'actorId'> {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string;
  address: SharedAddressInfo;
}

export type CustomerFormData = Omit<
  Customer,
  'uuid' | 'actions' | 'onboardedAt'
> & {
  onboardedAt: string;
};

export interface FetchCustomersParams extends TableFilter {
  name?: string;
  email?: string;
  actorId?: string;
  verificationStatus?: UserVerificationStatus;
  dateStart?: string;
  dateEnd?: string;
  sort?: string;
  sortDir?: 'asc' | 'desc';
}
