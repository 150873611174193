import React, { useCallback, useState } from 'react';
import { IconButton, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Action } from '../../types/customerTypes';
import { useCustomers } from '../../context/CustomerContext';
import slugToWords from '../../utils/slugToWords';
import ConfirmationDialog from '../common/ConfirmationDialog';

// Mapping between Action enum and icons
export const actionIcons = {
  [Action.VIEW]: <VisibilityIcon />,
};

interface ActionButtonsProps {
  actions: Action[];
  id: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ actions, id }) => {
  const { handleView } = useCustomers();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<Action | null>(null);
  const [currentId, setCurrentId] = useState<string | null>(null);

  const handleActionClick = useCallback(
    (event: React.MouseEvent, action: Action, id: string) => {
      event.stopPropagation();
      switch (action) {
        case Action.VIEW:
          handleView(id);
          break;
        default:
          break;
      }
    },
    []
  );

  const handleConfirmAction = async () => {
    if (currentAction && currentId) {
      switch (currentAction) {
        default:
          break;
      }
      setIsDialogOpen(false);
      setCurrentAction(null);
      setCurrentId(null);
    }
  };

  const handleCancelAction = () => {
    setIsDialogOpen(false);
    setCurrentAction(null);
    setCurrentId(null);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      {actions.map((action: Action, index: number) => (
        <IconButton
          key={index}
          aria-label={action}
          title={slugToWords(action)}
          onClick={event => handleActionClick(event, action, id)}
        >
          {actionIcons[action]}
        </IconButton>
      ))}
      <ConfirmationDialog
        open={isDialogOpen}
        onSubmit={handleConfirmAction}
        onCancel={handleCancelAction}
        title={`Confirm ${currentAction ? slugToWords(currentAction).toLowerCase() : ''}`}
        description={`Are you sure you want to ${currentAction ? slugToWords(currentAction).toLowerCase() : ''} this customer?`}
      />
    </Box>
  );
};

export default ActionButtons;
