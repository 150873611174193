import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import menuItems, { MenuItem } from '../../data/menuItems';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: 'none',
  padding: '0 24px',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  '& .MuiListItemIcon-root': {
    color: 'inherit',
  },
  '&:hover': {
    background: 'none',
    color: theme.palette.primary.light,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
}));

const ChevronIcon = styled(ExpandMoreIcon)<{ open: boolean }>(({ open }) => ({
  transition: 'transform 0.3s ease',
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (itemPath: string) => {
    setOpenItems(prev => ({
      ...prev,
      [itemPath]: !prev[itemPath],
    }));
  };

  const renderMenuItems = (items: MenuItem[], level = 0) =>
    items.map((item, index) => {
      const isOpen = openItems[item.path] || false;
      return (
        <React.Fragment key={index}>
          <ListItem
            component={item.children ? 'div' : Link}
            to={item.children ? undefined : item.path}
            disablePadding
          >
            <StyledListItemButton
              selected={location.pathname === item.path}
              onClick={() => item.children && handleToggle(item.path)}
              style={{ paddingLeft: `${(level + 1) * 16}px` }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
              {item.children && <ChevronIcon open={isOpen} />}
            </StyledListItemButton>
          </ListItem>
          {item.children && (
            <List
              component="div"
              disablePadding
              style={{ display: isOpen ? 'block' : 'none' }}
            >
              {renderMenuItems(item.children, level + 1)}
            </List>
          )}
        </React.Fragment>
      );
    });

  return <List>{renderMenuItems(menuItems)}</List>;
};

export default Sidebar;
