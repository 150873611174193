import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'top',
  height: '100vh',
  marginTop: '5rem',
}));

const Loader: React.FC = () => (
  <LoaderWrapper>
    <CircularProgress />
  </LoaderWrapper>
);

export default Loader;
