import { useState, useEffect } from 'react';

interface Config {
  instance: string;
  mode: 'development' | 'production';
  keycloakBaseUrl: string;
}

const fetchConfig = async () => {
  const response = await fetch(`${process.env.BASE_URL || '../'}config.json`);
  if (!response.ok) {
    throw new Error('Failed to fetch configuration');
  }
  return response.json();
};

const useConfig = () => {
  const [config, setConfig] = useState<Config | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const fetchedConfig = await fetchConfig();
        setConfig(fetchedConfig);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    loadConfig();
  }, []);
  return { config, loading, error };
};

export default useConfig;
