import React, { useCallback, useMemo, useState } from 'react';
import {
  TextField,
  Grid,
  Paper,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FetchCustomersParams,
  UserVerificationStatus,
} from '../../types/customerTypes';
import slugToWords from '../../utils/slugToWords';
import { useSearchParams } from 'react-router-dom';
import ClearableSelect from '../common/ClearableSelect';
import DateFilters from '../common/DateFilters';

interface CustomerSearchBarProps {
  onSearch: (params: Partial<FetchCustomersParams>) => void;
}

const StyledPaper = React.memo(
  styled(Paper)({
    padding: '8px 16px',
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  })
);

const StyledGrid = React.memo(
  styled(Grid)({
    flexWrap: 'nowrap',
    overflowX: 'auto',
    alignItems: 'baseline',
  })
);

const CustomerSearchBar: React.FC<CustomerSearchBarProps> = ({ onSearch }) => {
  const [initialSearchParams] = useSearchParams();
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [searchParams, setSearchParams] = useState<
    Partial<FetchCustomersParams>
  >({ ...Object.fromEntries(initialSearchParams.entries()) });

  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  const validateFields = () => {
    const newErrors: Record<string, string> = {};

    // Validate actorId for UUID format
    if (searchParams.actorId && !uuidRegex.test(searchParams.actorId)) {
      newErrors.actorId = 'Actor ID must be a valid UUID format';
    }

    // Validate dateStart and dateEnd
    const dateStart = new Date(searchParams.dateStart || '');
    const dateEnd = new Date(searchParams.dateEnd || '');

    if (searchParams.dateStart && searchParams.dateEnd && dateStart > dateEnd) {
      newErrors.dateStart = 'Start date cannot be greater than end date';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValid = useMemo(
    () =>
      (searchParams.actorId ||
        searchParams.name ||
        searchParams.email ||
        searchParams.dateStart ||
        searchParams.dateEnd ||
        searchParams.verificationStatus) &&
      validateFields(),
    [searchParams]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setSearchParams(searchParams => ({
        ...searchParams,
        [name]: value,
      }));
    },
    [setSearchParams]
  );

  const handleClearInput = useCallback(
    (name: string) => {
      setSearchParams(searchParams => ({
        ...searchParams,
        [name]: undefined,
      }));
    },
    [setSearchParams]
  );

  const handleSearch = useCallback(() => {
    const delayDebounce = setTimeout(() => {
      const isValid = validateFields();
      if (isValid) {
        onSearch(searchParams);
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [onSearch, searchParams, errors]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !errors.length) {
      onSearch(searchParams);
    }
  };

  const handleClear = () => {
    setSearchParams({});
    onSearch({});
  };

  const handleDateFilterChange = useCallback(
    (field: 'dateStart' | 'dateEnd', value: string) => {
      setSearchParams(searchParams => ({
        ...searchParams,
        [field]: value,
      }));
    },
    [setSearchParams]
  );

  return (
    <StyledPaper>
      <DateFilters
        dateEnd={searchParams?.dateEnd || ''}
        dateStart={searchParams?.dateStart || ''}
        onDateChange={handleDateFilterChange}
        errors={{
          dateStart: errors.dateStart,
          dateEnd: errors.dateEnd,
        }}
      />
      <div>
        <Typography variant="caption" fontWeight="bold">
          Customer
        </Typography>
        <StyledGrid container spacing={2} alignItems="center">
          <Grid item key="actorId">
            <TextField
              sx={{ minWidth: 200, maxWidth: 300 }}
              label="Actor Id"
              name="actorId"
              value={searchParams?.actorId || ''}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              onKeyDown={handleKeyDown}
              error={!!errors.actorId}
              helperText={errors.actorId || ''}
            />
          </Grid>
          <Grid item key="name">
            <TextField
              sx={{ minWidth: 200, maxWidth: 300 }}
              label={slugToWords('name')}
              name="name"
              value={searchParams?.name || ''}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item key="email">
            <TextField
              sx={{ minWidth: 200, maxWidth: 300 }}
              label={slugToWords('email')}
              name="email"
              value={searchParams?.email || ''}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item>
            <ClearableSelect
              label="Verification status"
              name="verificationStatus"
              value={searchParams?.verificationStatus || ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onClear={() => handleClearInput('verificationStatus')}
            >
              {Object.values(UserVerificationStatus).map(status => (
                <MenuItem key={status} value={status}>
                  {slugToWords(status)}
                </MenuItem>
              ))}
            </ClearableSelect>
          </Grid>
        </StyledGrid>
      </div>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button variant="outlined" onClick={handleClear} size="small">
            Clear
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={!isValid}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default CustomerSearchBar;
