import React from 'react';
import { IconButton, Tooltip, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNotifications } from '../../context/NotificationsContext';
import styled from '@emotion/styled';

const HiddenSpan = styled.span`
  display: inline-block;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
  font-weight: bold;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FormatUUID = ({ uuid }: { uuid?: string }) => {
  const { addNotification } = useNotifications();

  const handleCopy = () => {
    if (uuid) {
      navigator.clipboard.writeText(uuid);
      addNotification('UUID copied to clipboard', 'success');
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  if (!uuid) return <></>;

  return (
    <Wrapper>
      <Tooltip title={uuid}>
        <HiddenSpan>{uuid}</HiddenSpan>
      </Tooltip>
      <Tooltip title="Copy to clipboard">
        <IconButton onClick={handleCopy} size="small">
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Snackbar
        autoHideDuration={500}
        onClose={handleClose}
        message="UUID copied to clipboard"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
    </Wrapper>
  );
};

export default FormatUUID;
