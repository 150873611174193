import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import Keycloak from 'keycloak-js';

interface UseAuthReturn {
  keycloak: Keycloak.KeycloakInstance | null;
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  token: string | undefined;
  username: string | undefined;
  roles: string[];
  hasRole: (role: string) => boolean;
}

const useAuth = (): UseAuthReturn => {
  const { keycloak, authenticated, roles } = useContext(AuthContext);

  const login = () => {
    keycloak?.login();
  };

  const logout = () => {
    keycloak?.logout();
  };

  const isAuthenticated = authenticated;
  const token = keycloak?.token;
  const username = keycloak?.tokenParsed?.preferred_username;

  const hasRole = (role: string): boolean => {
    if (!roles) return false;
    return roles.includes(role);
  };

  return {
    keycloak,
    isAuthenticated,
    login,
    logout,
    token,
    username,
    roles,
    hasRole,
  };
};

export default useAuth;
