import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import PageWrapper from '../components/common/PageWrapper';
import { useCustomers } from '../context/CustomerContext';
import { formatDateTime } from '../utils/dateFormatter';
import { useTheme } from '@mui/material/styles';
import StatusChip from '../components/customer/StatusChip';
import StyledPaper from '../components/common/StyledPaper';
import NoDataPlaceholder from '../components/common/NoDataPlaceholder';

const CustomerDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { currentCustomer, loading, error, setCustomerId } = useCustomers();
  const theme = useTheme();

  useEffect(() => {
    if (id) {
      setCustomerId(id);
    }
    return () => {
      setCustomerId(undefined);
    };
  }, [id]);

  const memoizedTitle = useMemo(() => {
    return currentCustomer ? (
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {currentCustomer.fullName ? (
            <Typography variant="h4">{currentCustomer.fullName}</Typography>
          ) : (
            <NoDataPlaceholder variant="h4" />
          )}
        </Grid>
        <Grid item>
          <StatusChip status={currentCustomer.verificationStatus} />
        </Grid>
      </Grid>
    ) : (
      'Customer details'
    );
  }, [currentCustomer]);

  return (
    <PageWrapper error={error} loading={loading} title={memoizedTitle}>
      {currentCustomer && (
        <StyledPaper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Email:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.email ? (
                  <a href={`mailto:${currentCustomer.email}`} type="email">
                    {currentCustomer.email}
                  </a>
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Phone:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.phoneNumber ? (
                  currentCustomer.phoneNumber
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Country:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.address?.country?.name ? (
                  currentCustomer.address.country.name
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Postcode:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.address?.postcode ? (
                  currentCustomer.address.postcode
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Address Line 1:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.address?.address1 ? (
                  currentCustomer.address.address1
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Address Line 2:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.address?.address2 ? (
                  currentCustomer.address.address2
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Created:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.createTime ? (
                  formatDateTime(currentCustomer.createTime)
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Updated:
              </Typography>
              <Typography variant="body1">
                {currentCustomer.updateTime ? (
                  formatDateTime(currentCustomer.updateTime)
                ) : (
                  <NoDataPlaceholder />
                )}
              </Typography>
            </Grid>
          </Grid>
        </StyledPaper>
      )}
    </PageWrapper>
  );
};

export default CustomerDetails;
