import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import Loader from '../components/common/Loader';

interface KeycloakProviderProps {
  children: React.ReactNode;
}

const KeycloakProvider: React.FC<KeycloakProviderProps> = ({ children }) => {
  const auth = useContext(AuthContext);

  if (!auth.initialized) {
    return <Loader />;
  }

  if (auth.keycloak && !auth.authenticated) {
    auth.keycloak.login();
    return <div>Redirecting to login...</div>;
  }

  return <>{children}</>;
};

export default KeycloakProvider;
