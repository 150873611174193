import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { KeycloakInstance } from 'keycloak-js';
import keycloak from '../keycloak';

interface AuthContextProps {
  keycloak: KeycloakInstance | null;
  initialized: boolean;
  authenticated: boolean;
  roles: string[];
}

export const AuthContext = createContext<AuthContextProps>({
  keycloak: null,
  initialized: false,
  authenticated: false,
  roles: [],
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthContextProps>({
    keycloak: null,
    initialized: false,
    authenticated: false,
    roles: [],
  });

  useEffect(() => {
    keycloak
      .init({
        onLoad: 'login-required', // 'login-required' to enforce authentication on load
      })
      .then(authenticated => {
        setAuth({
          keycloak: keycloak,
          initialized: true,
          authenticated,
          roles: keycloak.realmAccess?.roles || [],
        });

        if (authenticated) {
          console.info('Authenticated');
        } else {
          console.warn('Not authenticated');
        }
      })
      .catch(error => {
        console.error('Keycloak initialization error:', error);
      });

    // Token refresh handling
    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(70) // Refresh token 70 seconds before it expires
        .then(refreshed => {
          if (refreshed) {
            console.log('Token refreshed');
          } else {
            console.warn(
              'Token not refreshed, valid for another',
              Math.round(
                (keycloak.tokenParsed?.exp ?? 0) +
                  (keycloak.timeSkew ?? 0) -
                  new Date().getTime() / 1000
              ),
              'seconds'
            );
          }
        })
        .catch(() => {
          console.error('Failed to refresh token');
        });
    };
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
