export interface PaginatedResponse<T> {
  items: T[];
  pagination: {
    total: number;
    limit: number;
    offset: number;
  };
}
export interface Filter {
  field: string; // Fields that can be filtered
  operator: 'contains' | 'equals' | 'startsWith' | 'endsWith' | 'gte' | 'lte'; // Example operators
  value: string | number | Date; // Value for filtering
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  // JPY = 'JPY',
  // Add more currencies as needed
}

export interface TableFilter {
  limit: number;
  offset: number;
}

export interface TableData<T> {
  items: T[];
  page: number;
  totalItems: number;
  totalPages: number;
}

export interface TableDataNew<T> {
  items: T[];
  page: number;
  size: number;
  total_items: number;
  total_pages: number;
}
