import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AuthProvider } from './context/AuthContext';
import KeycloakProvider from './context/KeycloakProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider>
    <KeycloakProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </KeycloakProvider>
  </AuthProvider>
);

reportWebVitals();
