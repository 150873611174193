import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Index from './components/layout';
import NotFound from './pages/NotFound';
import { NotificationsProvider } from './context/NotificationsContext';
import CustomerRoutes from './routes/CustomerRoutes';
import PaymentRoutes from './routes/PaymentRoutes';
import PrivateRoute from './components/common/PrivateRoute';

const App: React.FC = () => (
  <Router>
    <NotificationsProvider>
      <Index>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Navigate to="/customers" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers/*"
            element={
              <PrivateRoute>
                <CustomerRoutes />
              </PrivateRoute>
            }
          />
          <Route
            path="/banks/*"
            element={
              <PrivateRoute>
                <PaymentRoutes />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Index>
    </NotificationsProvider>
  </Router>
);

export default App;
