import { People, SvgIconComponent, AccountBalance } from '@mui/icons-material';

export type MenuItem = {
  text: string;
  icon?: React.ReactElement<SvgIconComponent>;
  path: string;
  children?: MenuItem[];
};

const menuItems: MenuItem[] = [
  {
    text: 'Customers',
    icon: <People />,
    path: '/customers',
  },
  {
    text: 'Banks',
    icon: <AccountBalance />,
    path: '/banks',
    children: [
      {
        text: 'MatchMove',
        path: '/banks/matchmove',
        children: [
          {
            text: 'Payments',
            path: '/banks/matchmove/payments',
          },
        ],
      },
    ],
  },
];

export default menuItems;
