import { TableFilter } from './common';

export enum BOPaymentMethod {
  INTRABANK = 'INTRABANK',
  SEPA = 'SEPA',
  SWIFT = 'SWIFT',
  UK_FP = 'UK_FP',
}
export enum PaymentStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  DECLINED = 'DECLINED',
  CREATED = 'CREATED',
}
export enum SharedPaymentDirection {
  PAYIN = 'PAYIN',
  PAYOUT = 'PAYOUT',
}
export interface PaymentShortInfo {
  amount: number;
  createdAt: string;
  currencyCode: string;
  direction: SharedPaymentDirection;
  faId: string;
  feeAmount: number;
  feeCurrencyCode: string;
  fingateId: string;
  method: BOPaymentMethod;
  paymentId: string;
  paymentStep: string;
  status: PaymentStatus;
  walletId: string;
}

export interface FetchPaymentsParams extends TableFilter {
  currencyCode?: string;
  method?: string;
  direction?: string;
  status?: string;
  paymentId?: string;
  faId?: string;
  fingateId?: string;
  walletId?: string;
  dateStart?: string;
  dateEnd?: string;
  sort?: string;
  sortDir?: 'asc' | 'desc';
}
