import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Payments from '../pages/Payments';
import NotFound from '../pages/NotFound';
import { PaymentProvider } from '../context/PaymentContext';
import PrivateRoute from '../components/common/PrivateRoute';
const PaymentRoutes: React.FC = () => (
  <PaymentProvider>
    <Routes>
      {/* Перенаправление с корневого пути банков */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to="matchmove/payments" /> {/* Относительный путь */}
          </PrivateRoute>
        }
      />
      {/* Платежный маршрут */}
      <Route
        path="matchmove/payments"
        element={
          <PrivateRoute>
            <Payments />
          </PrivateRoute>
        }
      />
      {/* Обработка всех остальных путей */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </PaymentProvider>
);

export default PaymentRoutes;
