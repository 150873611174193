import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { fetchPayments } from '../services/paymentService';
import { PaginatedResponse } from '../types/common';
import { PaymentShortInfo, FetchPaymentsParams } from '../types/paymentTypes';
import {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import useAuthenticatedApi from '../services/useAuthenticatedApi';

interface PaymentContextProps {
  paymentId?: string;
  payments: PaginatedResponse<PaymentShortInfo>;
  error: string | null;
  handleSearch: (params: Partial<FetchPaymentsParams>) => void;
  loading: boolean;
  paginationModel: GridPaginationModel;
  setPaymentId: (id?: string) => void;
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
  sortModel: GridSortModel;
  update: () => void;
  resetPageSettings: () => void;
  columnVisibilityModel: GridColumnVisibilityModel;
  setColumnVisibilityModel: React.Dispatch<
    React.SetStateAction<GridColumnVisibilityModel>
  >;
}

interface PaymentProviderProps {
  children: React.ReactNode;
}

const initialPaginationModel: GridPaginationModel = {
  page: 0,
  pageSize: 10,
};
const initialSortModel: GridSortModel = [];
const initialColumnVisibilityModel: GridColumnVisibilityModel = {};

const PaymentContext = createContext<PaymentContextProps | undefined>(
  undefined
);

export const PaymentProvider: React.FC<PaymentProviderProps> = ({
  children,
}) => {
  const [paymentId, setPaymentId] = useState<string>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    initialPaginationModel
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(initialColumnVisibilityModel);
  const [payments, setPayments] = useState<PaginatedResponse<PaymentShortInfo>>(
    {
      items: [],
      pagination: { total: 0, limit: 10, offset: 0 },
    }
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentTableSettings, setPaymentTableSettings] = useLocalStorage(
    'paymentTableSettings',
    { paginationModel, sortModel, columnVisibilityModel }
  );
  const { callApiWithAuth } = useAuthenticatedApi();

  useEffect(() => {
    if (paymentTableSettings) {
      setPaginationModel(paymentTableSettings.paginationModel);
      setSortModel(paymentTableSettings.sortModel);
      setColumnVisibilityModel(paymentTableSettings.columnVisibilityModel);
    } else {
      setPaymentTableSettings({
        paginationModel,
        sortModel,
        columnVisibilityModel,
      });
    }
  }, []);

  useEffect(() => {
    setPaymentTableSettings({
      paginationModel,
      sortModel,
      columnVisibilityModel,
    });
  }, [paginationModel, sortModel, columnVisibilityModel]);

  const getPayments = useCallback(async () => {
    setLoading(true);
    try {
      const data = await callApiWithAuth(fetchPayments, {
        ...Object.fromEntries(searchParams.entries()),
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: sortModel[0]?.field,
        sortDir: sortModel[0]?.sort || undefined,
      });
      setPayments(prevState => ({
        items: data.items,
        pagination: {
          total: data.total_items,
          limit: prevState.pagination.limit,
          offset: prevState.pagination.offset,
        },
      }));
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [searchParams, paginationModel, sortModel]);

  useEffect(() => {
    setError(null);
    getPayments();
  }, [paginationModel, sortModel, searchParams, paymentId]);

  const handleSearch = (params: Partial<FetchPaymentsParams>) => {
    const nonEmptyParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value)
    ) as URLSearchParamsInit;
    setSearchParams(nonEmptyParams);
  };

  const update = async () => {
    setLoading(true);
    try {
      const data = await callApiWithAuth(fetchPayments, {
        ...searchParams,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: sortModel[0]?.field,
        sortDir: sortModel[0]?.sort || undefined,
      });
      setPayments(data);
      setPaginationModel(() => ({
        page: data.page,
        pageSize: data.pageSize,
      }));
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const resetPageSettings = async () => {
    setPaginationModel(initialPaginationModel);
    setSortModel(initialSortModel);
    setColumnVisibilityModel(initialColumnVisibilityModel);
  };

  return (
    <PaymentContext.Provider
      value={{
        payments,
        error,
        handleSearch,
        loading,
        paginationModel,
        setPaginationModel,
        setSortModel,
        sortModel,
        paymentId,
        setPaymentId,
        update,
        columnVisibilityModel,
        setColumnVisibilityModel,
        resetPageSettings,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayments = (): PaymentContextProps => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};
