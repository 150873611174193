import React from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';

const StyledTextField = React.memo(
  styled(TextField)({ minWidth: 200, maxWidth: 300 })
);

const SmallClearIcon = styled(ClearIcon)({
  fontSize: '1rem',
});

const SmallIconButton = styled(IconButton)({
  marginRight: 8,
});

interface ClearableSelectProps extends Omit<TextFieldProps, 'select'> {
  label: string;
  name: string;
  value: string | number | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  onClear: () => void;
}

const ClearableSelect: React.FC<ClearableSelectProps> = ({
  label,
  name,
  value,
  onChange,
  onKeyDown,
  children,
  onClear,
  ...props
}) => {
  return (
    <StyledTextField
      select
      label={label}
      name={name}
      value={value || ''}
      onChange={onChange}
      onKeyDown={onKeyDown}
      variant="outlined"
      size="small"
      InputProps={{
        endAdornment:
          value && onClear ? (
            <InputAdornment position="end">
              <SmallIconButton size="small" onClick={onClear}>
                <SmallClearIcon />
              </SmallIconButton>
            </InputAdornment>
          ) : null,
      }}
      {...props}
    >
      {children}
    </StyledTextField>
  );
};

export default ClearableSelect;
