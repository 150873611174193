import apiClient from './apiClient';
import { PaymentShortInfo, FetchPaymentsParams } from '../types/paymentTypes';
import { TableDataNew } from '../types/common';

export const fetchPayments = async (
  params: FetchPaymentsParams,
  headers?: Record<string, string>
): Promise<TableDataNew<PaymentShortInfo>> => {
  const pageNumber = Math.floor(params.offset / params.limit) + 1;

  const response = await apiClient.get('fingate-gateway-api/bo/payments', {
    params: {
      ...(params.currencyCode
        ? { currency: params.currencyCode.toUpperCase() }
        : {}),
      ...(params.faId ? { faId: params.faId } : {}),
      ...(params.paymentId ? { id: params.paymentId } : {}),
      ...(params.direction ? { direction: params.direction } : {}),
      ...(params.method ? { method: params.method } : {}),
      ...(params.status ? { status: params.status } : {}),
      ...(params.fingateId ? { fingateId: params.fingateId } : {}),
      ...(params.walletId ? { walletId: params.walletId } : {}),
      ...(params.dateStart
        ? { begin_date: new Date(params.dateStart).toISOString() }
        : {}),
      ...(params.dateEnd
        ? { end_date: new Date(params.dateEnd).toISOString() }
        : {}),
      per_page: params.limit,
      page: pageNumber,
      sortColumn: params.sort,
      sortOrder: params.sortDir?.toUpperCase(),
    },
    headers: headers,
  });
  return response.data;
};
