import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Grid,
  Paper,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  BOPaymentMethod,
  FetchPaymentsParams,
  PaymentStatus,
} from '../../types/paymentTypes';
import slugToWords from '../../utils/slugToWords';
import { useSearchParams } from 'react-router-dom';
import { Currency } from '../../types/common';
import ClearableSelect from '../common/ClearableSelect';
import DateFilters from '../common/DateFilters';

interface PaymentSearchBarProps {
  onSearch: (params: Partial<FetchPaymentsParams>) => void;
}

const StyledPaper = React.memo(
  styled(Paper)({
    padding: '8px 16px',
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  })
);

const StyledGrid = React.memo(
  styled(Grid)({
    flexWrap: 'nowrap',
    overflowX: 'auto',
    alignItems: 'baseline',
  })
);

const StyledTextField = React.memo(
  styled(TextField)({ minWidth: 170, maxWidth: 300 })
);

const PaymentSearchBar: React.FC<PaymentSearchBarProps> = ({ onSearch }) => {
  const [initialSearchParams] = useSearchParams();
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [searchParams, setSearchParams] = useState<
    Partial<FetchPaymentsParams>
  >({ ...Object.fromEntries(initialSearchParams.entries()) });

  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  const validateFields = () => {
    const newErrors: Record<string, string> = {};

    // Validate walletId for UUID format
    if (searchParams.walletId && !uuidRegex.test(searchParams.walletId)) {
      newErrors.walletId = 'Wallet ID must be a valid UUID format';
    }
    // Validate paymentId for UUID format
    if (searchParams.paymentId && !uuidRegex.test(searchParams.paymentId)) {
      newErrors.paymentId = 'Payment ID must be a valid UUID format';
    }

    // Validate dateStart and dateEnd
    const dateStart = new Date(searchParams.dateStart || '');
    const dateEnd = new Date(searchParams.dateEnd || '');

    if (searchParams.dateStart && searchParams.dateEnd && dateStart > dateEnd) {
      newErrors.dateStart = 'Start date cannot be greater than end date';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValid = useMemo(
    () =>
      (searchParams.currencyCode ||
        searchParams.method ||
        searchParams.direction ||
        searchParams.status ||
        searchParams.paymentId ||
        searchParams.fingateId ||
        searchParams.walletId ||
        searchParams.dateStart ||
        searchParams.dateEnd ||
        searchParams.faId) &&
      validateFields(),
    [searchParams]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setSearchParams(searchParams => ({
        ...searchParams,
        [name]: value,
      }));
    },
    [setSearchParams]
  );

  const handleClearInput = useCallback(
    (name: string) => {
      setSearchParams(searchParams => ({
        ...searchParams,
        [name]: undefined,
      }));
    },
    [setSearchParams]
  );

  const handleSearch = useCallback(() => {
    const delayDebounce = setTimeout(() => {
      const isValid = validateFields();
      if (isValid) {
        onSearch(searchParams);
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [onSearch, searchParams, errors]);

  useEffect(() => {
    validateFields();
  }, [searchParams]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !errors.length) {
      onSearch(searchParams);
    }
  };

  const handleClear = () => {
    setSearchParams({});
    onSearch({});
  };

  const handleDateFilterChange = useCallback(
    (field: 'dateStart' | 'dateEnd', value: string) => {
      setSearchParams(searchParams => ({
        ...searchParams,
        [field]: value,
      }));
    },
    [setSearchParams]
  );

  return (
    <StyledPaper>
      <DateFilters
        dateEnd={searchParams?.dateEnd || ''}
        dateStart={searchParams?.dateStart || ''}
        onDateChange={handleDateFilterChange}
        errors={{
          dateStart: errors.dateStart,
          dateEnd: errors.dateEnd,
        }}
      />
      <div>
        <Typography variant="caption" fontWeight="bold">
          Payment
        </Typography>
        <StyledGrid container spacing={1} alignItems="center">
          <Grid item key="walletId">
            <StyledTextField
              label="Wallet id"
              name="walletId"
              value={searchParams?.walletId || ''}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              onKeyDown={handleKeyDown}
              error={!!errors.walletId}
              helperText={errors?.walletId || ''}
            />
          </Grid>
          <Grid item key="paymentId">
            <StyledTextField
              label="Payment Id"
              name="paymentId"
              value={searchParams?.paymentId || ''}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item key="fingateId">
            <StyledTextField
              label="Fingate Id"
              name="fingateId"
              value={searchParams?.fingateId || ''}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item key="currencyCode">
            <ClearableSelect
              label="Currency code"
              name="currencyCode"
              value={searchParams?.currencyCode || ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onClear={() => handleClearInput('currencyCode')}
            >
              {Object.values(Currency).map(currency => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </ClearableSelect>
          </Grid>
          <Grid item>
            <ClearableSelect
              label="Status"
              name="status"
              value={searchParams?.status || ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onClear={() => handleClearInput('status')}
            >
              {Object.values(PaymentStatus).map(status => (
                <MenuItem key={status} value={status}>
                  {slugToWords(status)}
                </MenuItem>
              ))}
            </ClearableSelect>
          </Grid>
          <Grid item>
            <ClearableSelect
              label="Payment method"
              name="method"
              value={searchParams?.method || ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onClear={() => handleClearInput('method')}
            >
              {Object.values(BOPaymentMethod).map(method => (
                <MenuItem key={method} value={method}>
                  {slugToWords(method)}
                </MenuItem>
              ))}
            </ClearableSelect>
          </Grid>
        </StyledGrid>
      </div>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button variant="outlined" onClick={handleClear} size="small">
            Clear
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={!isValid}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default PaymentSearchBar;
