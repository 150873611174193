import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

interface NoDataPlaceholderProps extends TypographyProps {
  variant?: TypographyProps['variant'];
}

const NoDataPlaceholder: React.FC<NoDataPlaceholderProps> = ({
  variant = 'body1',
  ...props
}) => {
  return (
    <StyledTypography variant={variant} {...props}>
      No data available
    </StyledTypography>
  );
};

export default NoDataPlaceholder;
