import React, { useEffect } from 'react';
import CustomerTable from '../components/customer/CustomerTable';
import PageWrapper from '../components/common/PageWrapper';
import CustomerSearchBar from '../components/customer/CustomerSearchBar';
import { useCustomers } from '../context/CustomerContext';
const Customers: React.FC = () => {
  const {
    error,
    loading,
    customers,
    handleSearch,
    paginationModel,
    setSortModel,
    sortModel,
    setPaginationModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
    setCustomerId,
    resetPageSettings,
  } = useCustomers();

  useEffect(() => {
    setCustomerId(undefined);
  }, []);

  return (
    // Wrap content in PageWrapper to handle loading and error states
    <PageWrapper
      error={error}
      loading={loading}
      onResetPageSettings={resetPageSettings}
    >
      <CustomerSearchBar onSearch={handleSearch} />
      <CustomerTable
        rows={customers}
        loading={loading}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      />
    </PageWrapper>
  );
};

export default Customers;
