import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 25, width: 32 }}>
      <svg
        width="100"
        height="48"
        viewBox="0 0 100 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="24"
          cy="24"
          r="24"
          transform="rotate(-90 24 24)"
          fill="#7D42FF"
        />
        <rect
          x="56"
          y="46"
          width="44"
          height="44"
          transform="rotate(-90 56 46)"
          fill="#77DAC9"
        />
      </svg>
    </SvgIcon>
  );
}
