import useAuth from '../hooks/useAuth'; // Assuming you have a custom useAuth hook

const useAuthenticatedApi = () => {
  const { token } = useAuth();
  const callApiWithAuth = async (
    // eslint-disable-next-line
    apiFunction: (params: any, headers?: any) => Promise<any>,
    // eslint-disable-next-line
    params: any
  ) => {
    try {
      const headers = {
        'X-Legal-Entity-Id': 'SPIKE',
        Authorization: `Bearer ${token}`,
      };
      return await apiFunction(params, headers);
    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  };

  return {
    callApiWithAuth,
  };
};

export default useAuthenticatedApi;
