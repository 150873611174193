import apiClient from './apiClient';
import {
  Customer,
  CustomerDetails,
  FetchCustomersParams,
} from '../types/customerTypes';
import { TableData } from '../types/common';

export const fetchCustomers = async (
  params: FetchCustomersParams,
  headers?: Record<string, string>
): Promise<TableData<Customer>> => {
  const { name, email, actorId, verificationStatus } = params;
  const pageNumber = Math.floor(params.offset / params.limit) + 1;

  const response = await apiClient.get(
    '/kyc-service-api/bo/customers/individuals',
    {
      params: {
        ...(name ? { name } : {}),
        ...(email ? { email } : {}),
        ...(actorId ? { actorId } : {}),
        ...(verificationStatus ? { verificationStatus } : {}),
        ...(params.dateStart
          ? { begin_date: params.dateStart.slice(0, 10) }
          : {}),
        ...(params.dateEnd ? { end_date: params.dateEnd.slice(0, 10) } : {}),
        per_page: params.limit,
        page: pageNumber,
        sortColumn: params.sort,
        sortOrder: params.sortDir?.toUpperCase(),
      },
      headers: headers,
    }
  );
  return response.data;
};

export const fetchCustomerById = async (
  actorId: string,
  headers?: Record<string, string>
): Promise<CustomerDetails> => {
  const response = await apiClient.get(
    `/kyc-service-api/bo/customers/individuals/${actorId}`,
    {
      headers: headers,
    }
  );
  return response.data;
};
