import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  CssBaseline,
  IconButton,
  Drawer,
  Divider,
  Box as MuiBox,
} from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import Sidebar from './Sidebar';
import Header from './Header';
import Main from './Main';
import { useLocation } from 'react-router-dom';
import menuItems from '../../data/menuItems';
import NotificationsList from '../notification/NotificationsList';
import { drawerWidth } from '../../theme';

const DrawerStyled = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(open
      ? {}
      : {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const StyledBox = styled(MuiBox)({
  display: 'flex',
});

const MainBox = styled(MuiBox)(() => ({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
}));

const usePageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const menuItem = menuItems.find(item => item.path === location.pathname);
    document.title = menuItem ? menuItem.text : 'Compass';
  }, [location]);
};

const Index: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(true);
  usePageTitle();

  const toggleDrawer = () => {
    setOpen(state => !state);
  };

  return (
    <StyledBox>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={toggleDrawer} />
      <DrawerStyled variant="permanent" open={open}>
        <StyledToolbar>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </StyledToolbar>
        <Divider />
        <Sidebar />
      </DrawerStyled>
      <MainBox component="main">
        <Toolbar />
        <Main>{children}</Main>
      </MainBox>
      <NotificationsList />
    </StyledBox>
  );
};

export default Index;
