import React from 'react';
import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import slugToWords from '../../utils/slugToWords';
import { PaymentStatus } from '../../types/paymentTypes';

interface StatusChipProps {
  status: PaymentStatus;
}

const getStatusChipColor = (status: PaymentStatus) => {
  const theme = useTheme();
  switch (status) {
    case 'COMPLETED':
      return theme.palette.success.light;
    case 'CREATED':
      return 'default';
    case 'PROCESSING':
      return theme.palette.warning.light;
    case 'FAILED':
    case 'DECLINED':
      return theme.palette.error.light;
    default:
      return theme.palette.grey[500];
  }
};

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const theme = useTheme();
  const backgroundColor = getStatusChipColor(status);
  const textColor = theme.palette.getContrastText(backgroundColor);

  return (
    <Chip
      size="small"
      label={slugToWords(status)}
      sx={{
        backgroundColor,
        color: textColor,
      }}
    />
  );
};

export default StatusChip;
